<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: 100vh;">
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <PageHeader :title="__('Upload Data')"></PageHeader>
        </el-col>
      </el-row>

      <el-form
        label-position="top"
        ref="uploadDataForm"
        :model="form"
        label-width="100px"
        :rules="rules"
        v-loading="isSubmitting"
        class="form-container"
      >
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item :label="__('Manage Table Data')"></el-form-item>

            <el-row>
              <el-col>
                <el-form-item>
                  <el-button @click="downloadTemplate"
                    >{{ __("Download template to add data") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- Hiding these btns for now as they do not have a call to action -->
            <!-- <el-row>
              <el-col>
                <el-form-item>
                  <el-button @click="downloadUploadedData"
                    >Export Data to CSV
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-button @click="downloadUploadedData"
                    >Export Data to a ZIP file
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col>
                <el-form-item
                  prop="file_path"
                  :label="
                    __('Upload Values for :display_name Datastore', {
                      display_name: datastore.display_name
                    })
                  "
                ></el-form-item>
                <csv-uploader
                  :file-name="file_name"
                  :file-url="file_url"
                  folder="temp"
                  @on-success="handleSuccess"
                  @on-delete="handleDelete"
                  @on-progress="isLoading = true"
                  @on-error="handleError"
                />
              </el-col>
            </el-row>
            <el-row class="pt-10">
              <el-col>
                <el-form-item prop="insert_type">
                  <el-radio-group
                    style="display: flex; flex-direction: column"
                    v-model="form.insert_type"
                  >
                    <el-radio label="append" class="pt-5"
                      >{{ __("Append to existing data") }}
                    </el-radio>
                    <el-radio label="replace" class="pt-5"
                      >{{ __("Replace existing data and then insert") }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-form-item label="Format-Specific Options" prop="separator">
                  <el-row style="margin-left:20px">
                    <el-col :span="6" style="min-width: 160px">
                      <span>Columns separated with:</span>
                    </el-col>
                    <el-col
                      :span="8"
                      style="max-width:100px; margin-left: 20px"
                    >
                      <el-input
                        type="text"
                        maxlength="20"
                        style="display:inline"
                        placeholder=","
                        v-model="form.separator"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12">
                <el-button
                  :disabled="isLoading"
                  class="submitBtn"
                  @click="submit"
                  >{{ __("Submit") }}
                </el-button>
                <el-button class="cancelBtn" @click="handleCancel()"
                  >{{ __("Cancel") }}
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {
  getDatastoreDataTemplate,
  uploadDataStoreData
} from "@/api/dataStores";
import FileDownloader from "@/mixins/FileDownloader";
import { removeFiles } from "@/api/services";
import CsvUploader from "@/components/uploaders/types/CsvUploader";
import _ from "lodash";

export default {
  name: "uploadData",
  mixins: [FileDownloader],
  components: {
    CsvUploader,
    PageHeader
  },
  props: {
    datastore: {
      type: Object,
      required: true
    }
  },
  data() {
    const validateSeparator = (rule, value, callback) => {
      if (!_.isEmpty(value)) {
        if (this.form.separator.length > 1 && this.form.separator !== "\\t") {
          callback("Delimiter can only accept a single character");
        }
      }
      callback();
    };
    return {
      form: {
        file_path: "",
        insert_type: "append",
        separator: ","
      },
      file_url: "",
      file_name: "",
      isLoading: false, // to disable submit button while the upload is in progress
      isSubmitting: false,
      uploadedFilePaths: [],
      rules: {
        file_path: {
          required: true,
          message: __("Please upload a file")
        },
        separator: {
          validator: validateSeparator,
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    downloadTemplate() {
      getDatastoreDataTemplate(this.datastore).then(response => {
        this.downloadFile(
          response.data,
          `datatstore_${this.datastore.display_name}_csv_data.csv`
        );
      });
    },
    downloadUploadedData() {
      // getListBasedCampaignUploadedData(this.datastore).then(response => {
      //   this.downloadFile(
      //     response.data,
      //     `campaign_${this.datastore.display_name}_csv_uploaded_data.csv`
      //   );
      // });
    },
    handleError() {
      this.isLoading = false;
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.uploadedFilePaths.push(url.path);
      this.form.file_path = url.path;
      this.file_url = url.url;
      this.file_name = url.uploaded_file_name;
      this.$refs.uploadDataForm.validateField("file_path");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleDelete() {
      this.resetForm();
    },
    resetForm() {
      this.form.file_path = "";
      this.file_url = "";
      this.file_name = "";
      this.form.insert_type = "append";
      this.form.separator = ",";
    },
    removeUploadedFiles() {
      removeFiles(this.uploadedFilePaths);
    },
    submit() {
      this.$refs.uploadDataForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          // replace \t with the tab
          this.$set(
            this.form,
            "separator",
            this.form.separator.replace(/\\t/g, "\t")
          );
          uploadDataStoreData(this.datastore, this.form)
            .then(({ data }) => {
              this.$message({
                message: data.message,
                type: "info"
              });
              this.$emit("cancel");
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: "error"
              });
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    }
  },
  beforeDestroy() {
    this.removeUploadedFiles();
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/button.scss";

.pt-10 {
  padding-top: 10px;
}

.pt-5 {
  padding-top: 5px;
}
</style>
